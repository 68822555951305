<!-- 显示特惠专区商品商品 -->
<template>
  <div class="container">
    <div class="mui-content" v-if="product">
      <van-swipe :autoplay="bannerTime" @change="onChange">
        <van-swipe-item class="video-box" v-for="(item, index) in product.BannerVideo" :key="index"
          :class="{ 'playing': item.isPlaying }">
          <img v-lazy="item.pic_url" />
          <span class="icon-video" @click="miPlay(item)"></span>
          <video :src="item.video_url" controls="controls" ref="videoList" :data-id="item.id"
            @pause="miPause(item)"></video>
        </van-swipe-item>
        <van-swipe-item v-for="(image, index) in product.Banner" :key="index">
          <img v-lazy="image.pic_url" preview="0" preview-text="" />
        </van-swipe-item>
        <div class="custom-indicator" slot="indicator">
          {{ current + 1 }}/{{ product.Banner.length + (product.BannerVideo ? product.BannerVideo.length : 0) }}
        </div>
      </van-swipe>
      <!--商品信息-->
      <div class="product-body">
        <div class="price-box">
          <template v-for="(item, index) in product.PriceInfoList">
            <div v-if="item.LevelName !== '零售'" :key="index">
              <span class="vip">￥</span><span class="vip number">{{ item.priceAgo }}</span>
              <span class="vip-text">{{ item.LevelName }}价</span>
            </div>
          </template>
          <template v-for="(item, idx) in product.PriceInfoList">
            <del v-if="item.LevelName === '零售'" :key="idx">零售价:￥{{ item.priceAgo }}</del>
          </template>
          <div class="operate" @click="createPoster()">
            <div class="share-icon"><van-icon name="share" /></div>
            <div class="text">分享</div>
          </div>
        </div>
        <div class="pro-name mui-ellipsis">{{ product.ProductName }}</div>
        <div v-if="product.deliveryTimeStr" class="delivery-time mui-ellipsis2">({{ product.deliveryTimeStr }})
        </div>
        <p class="sold-num">已订购{{ product.buyedcountStr }}件</p>
      </div>
      <!--商品规格-->
      <div class="mi-norm">
        <div class="mi-item">
          <label>规格</label>
          <div class="mi-item-body">
            <p>{{ product.product_content }}</p>
          </div>
        </div>
        <div class="mi-item">
          <label>功效</label>
          <div class="mi-item-body">
            <p>{{ product.product_effect_detail }}</p>
          </div>
        </div>
        <div class="mi-item">
          <label>信息</label>
          <div class="mi-item-body">
            <p>产品参数值{{ product.ProductValue }}</p>
            <!-- <template v-for="price in product.PriceInfoList">
              <p v-if="price.LevelName !== '零售'">{{ price.LevelName }}价（首单混批{{ price.MinCount }}起）</p>
            </template> -->
          </div>
        </div>
      </div>
      <van-tabs v-model="subTabId" class="pro-tabs" :border="false">
        <van-tab title="商品详情">
          <div class="sub-title">
            <span>商品详情</span>
          </div>
          <div class="pro-detail" v-html="product.ProductIntro"></div>
          <div class="ivv-notice" v-for="(it, idx) in purchaseNotice" :key="idx">
            <img :src="it.url" />
          </div>
        </van-tab>
        <van-tab title="检验证明">
          <div class="sub-title">
            <span>检验证明</span>
          </div>
          <div class="pro-detail" v-html="product.AcContents"></div>
        </van-tab>
        <van-tab title="测评报告">
          <div class="sub-title">
            <span>测评报告</span>
          </div>
          <div class="pro-detail" v-html="product.ShowContens"></div>
        </van-tab>
        <van-tab title="备案查询">
          <div class="sub-title">
            <span>备案查询</span>
          </div>
          <div class="pro-detail" v-html="product.Register"></div>
        </van-tab>
      </van-tabs>
      <van-image-preview v-model="isPreViewSHow" :images="preViewList" :start-position="imgIndex"></van-image-preview>
      <div class="layer mi-share" v-show="isShowShareLayer" @click="isShowShareLayer = false">
        <div class="mi-poster" v-if="product.posterUrl" @click.stop>
          <img :src="product.posterUrl" />
          <div class="prompt" @click="downloadImg()" v-if="isInApp === '1' && product.posterUrl">
            <span>保存图片到相册</span>
          </div>
          <div class="prompt" v-else>
            <img src="../../assets/images/long_press.png" />
            <span>长按选择</span><span>保存图片</span>
          </div>

        </div>
        <canvas ref="myCanvas"></canvas>
      </div>
    </div>
    <div class="footer">
      <div class="shop-cart" @click="handleBack">
        返回列表
      </div>
      <div class="total"> </div>
      <van-button type="primary" color="#FF98A0" @click="handlePurchase()">立即抢购</van-button>
    </div>
  </div>
</template>

<script>
import ProductDetail from "../../components/Product/Detail.vue";
import { deliveryMessage } from "../../utils/appUtil";
import shareBtmImage from "../../assets/images/prod_share_btm.png";
export default {
  name: 'SpecialProductDetail',
  components: { ProductDetail },
  data() {
    return {
      brandId: 3,
      goodId: '',
      product: null,
      totalCount: 0,
      totalAmount: 0,
      value: 0,
      addCount: 0,
      max: 9999,
      preViewList: [],
      isPreViewSHow: false,
      isShowShareLayer: false,
      subTabId: 0,
      current: 0,//轮播索引
      imgIndex: 0,//图片预览页码
      bannerTime: 5000,
      isInApp: '0',
      levelName: '', //享受价格名称
      purchaseNotice: [],//购买告知书
      levelTitle: 1, //1新人 2超V 3一级 4省代 5大区
      userIdentityV4: -1, //新人-1 超V 0 代理1 取消授权2
      showPoster: false,
      qrCode: "",

    }
  },
  created() {
    let { inApp } = this.$route.query;
    if (inApp === '1') this.isInApp = '1';
    let { goodId, brandId } = this.$route.query;
    this.goodId = +goodId;
    this.brandId = +brandId;
    this.getProductById();
    this.getUserIdentityNew();
    this.generateQrCode();
  },
  methods: {
    //获取用户身份信息
    getUserIdentityNew() {
      this.post("/TeamAgent/AgentInfo/HomeIdentity_V4", {}, 2).then(json => {
        if (json && json.code === 1) {
          //0：待审核，1：已审核， 3：未建立经销体系 4：黑名单，5：取消授权
          // userIdentityV4  新人-1 超V 0 代理1 取消授权2
          let { mzState, jkState, jkLevel, mzLevel } = json.response;
          if (this.brandId === 3) {
            if (mzLevel > 2) {
              this.userIdentityV4 = ([4, 5].indexOf(mzState) >= 0) ? 2 : 1;
            } else {
              this.userIdentityV4 = mzLevel === 2 ? 0 : -1;
            }
            this.levelTitle = mzLevel;
          } else {
            if (jkLevel > 2) {
              this.userIdentityV4 = ([4, 5].indexOf(jkState) >= 0) ? 2 : 1;
            } else {
              this.userIdentityV4 = (jkLevel === 2) ? 0 : -1;
            }
            this.levelTitle = jkLevel;
          }
        }
      });
    },
    //根据商品id获取商品数据
    getProductById: function () {
      this.$toast.loading({ duration: 0, message: '加载中...', mask: true });
      this.get('/GroupBuy/SubmitOrder/ProductView?productId=' + this.goodId + "&isJuhuiOrder=1", {}).then(json => {
        this.$toast.clear();
        if (json.code === 1) {
          let { selloutImg, goodsId } = json.response;
          if (selloutImg) this.max = 0;
          let data = json.response;
          this.product = data
        }
      });
    },
    //轮播
    onChange: function (index) {
      this.current = index;
      let list = this.$refs.videoList;
      if (list && list.length > 0) {
        for (let i = 0; i < list.length; i++) {
          list[i].pause();
        }
      }
    },
    handleBack() {
      this.$router.back();
    },
    //点击立即抢购
    handlePurchase() {
      if (this.userIdentityV4 === 1) {
        this.$dialog.confirm({
          title: "提示",
          message: "您已经是旗舰店主，请前往“采购订货”购买活动套餐哦！",
          confirmButtonText: "立即前往",
          confirmButtonColor: '#ff98a0'
        }).then(() => {
          if (this.isInApp === '1') {
            deliveryMessage({ 'action': 'jumpStore', data: {} });
            return
          }
          window.location.href = this.baseHost + "/7999/Arongleweb/html/groupon/groupon_list.html?brandId=" + this.brandId;
        }).catch(() => { })
        return;
      }
      window.location.href = this.baseHost + "/#/upgradeZoneConfirm?goodId=" + this.goodId + "&num=1" + "&brandId=" + this.brandId + "&inApp=" + (this.isInApp ? 1 : 0)
    },
    //生成海报
    createPoster: function () {
      let product = this.product;
      if (product.posterUrl) {
        this.isShowShareLayer = true;
      } else {
        this.$toast.loading({ message: "海报生成中...", duration: 0, mask: true });
        this.get("/GroupBuy/Product/ShareImg", { goodsid: this.goodId }).then(json => {
          if (json.code === 1 && json.response) {
            this.drawProduct(product, json.response);
          } else {
            this.$toast("分享海报失败");
          }
        })
      }
    },
    generateQrCode: function () {
      this.post('/base/ToolsC/QrCode?', {
        size: 6,
        url: this.baseHost + "/#/upgradeZone/detail?goodId=" + this.goodId + "&brandId=" + this.brandId
      }).then(json => {
        this.$toast.clear();
        if (json.code === 1) {
          let img = new Image();
          img.src = json.response;
          img.onload = () => {
            this.qrCode = img;
          }
        } else {
          console.log('走没走这里');
        }
      });
    },
    //产品分享海报
    drawProduct: function (product, url) {
      if (!this.qrCode) {
        setTimeout(() => {
          this.drawProduct(product, url);
        }, 100)
        return;
      }

      let width = 600;
      let height = 892;
      let initHieght = 0;
      let canvas = this.$refs.myCanvas;
      canvas.width = width;
      canvas.height = height;
      let ctx = canvas.getContext("2d");
      this.fillRoundRect(ctx, 0, 0, width, height, 30, '#fff');
      ctx.clip();
      let imgWXCode = new Image();
      imgWXCode.src = shareBtmImage;
      imgWXCode.onload = () => {
        let imgProduct = new Image();
        imgProduct.src = url;
        imgProduct.width = 600;
        imgProduct.height = 600;
        imgProduct.onload = () => {
          console.log(imgProduct.width, imgProduct.height)
          //画产品图  --- 产品图的宽度和画布宽度一致
          ctx.drawImage(imgProduct, 0, initHieght, imgProduct.width, imgProduct.height);
          initHieght += imgProduct.height;
          ctx.drawImage(imgWXCode, 0, initHieght, imgWXCode.width, imgWXCode.height);
          initHieght += 30;
          //画二维码
          ctx.drawImage(this.qrCode, (width - 210), initHieght, 200, 200);
          initHieght += 200 + 20;
          //画长按识别二维码文字
          ctx.font = "19px Arial";
          ctx.fillStyle = "#999";
          ctx.fillText('长按识别公众号二维码', width - 205, initHieght);
          this.product.posterUrl = canvas.toDataURL("image/png");
          this.$toast.clear();
          this.isShowShareLayer = true;
        }
      };
    },
    fillRoundRect: function (cxt, x, y, width, height, radius, fillColor) {
      //圆的直径必然要小于矩形的宽高
      if (2 * radius > width || 2 * radius > height) {
        return false;
      }
      cxt.save();
      cxt.translate(x, y);
      //绘制圆角矩形的各个边
      this.drawRoundRect(cxt, x, y, width, height, radius);
      cxt.fillStyle = fillColor || "#000"; //若是给定了值就用给定的值否则给予默认值
      cxt.fill();
      cxt.restore();
    },
    drawRoundRect: function (cxt, x, y, width, height, radius) {
      cxt.beginPath();
      cxt.arc(x + radius, y + radius, radius, Math.PI, Math.PI * 3 / 2);
      cxt.lineTo(width - radius + x, y);
      cxt.arc(width - radius + x, radius + y, radius, Math.PI * 3 / 2, Math.PI * 2);
      cxt.lineTo(width + x, height + y - radius);
      cxt.arc(width - radius + x, height - radius + y, radius, 0, Math.PI * 1 / 2);
      cxt.lineTo(radius + x, height + y);
      cxt.arc(radius + x, height - radius + y, radius, Math.PI * 1 / 2, Math.PI);
      cxt.closePath();
    },
    downloadImg: function () {
      let message = { 'action': 'downImg', 'data': { 'url': this.product.posterUrl } };
      deliveryMessage(message);
    },
  }
}
</script>
<style lang='scss' scoped>
.container {
  padding-bottom: 55px;
}

.mui-content {
  margin-bottom: 60px;
  background-color: #f8f8f8;

  .van-swipe {
    .van-swipe-item {
      font-size: 0;
    }

    img {
      width: 100%;
      height: 100%;
    }

    .custom-indicator {
      position: absolute;
      color: #fff;
      right: 15px;
      bottom: 15px;
      padding: 2px 15px;
      border-radius: 20px;
      background-color: rgba(0, 0, 0, 0.4);
    }

    .video-box {
      img {
        position: relative;
        z-index: 10;
      }

      video {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 5;
      }

      .icon-video {
        position: absolute;
        color: #fff;
        top: calc((100% - 60px)/2);
        left: calc((100% - 60px)/2);
        z-index: 12;
        font-size: 60px;

        &:before {
          content: "\e933";
        }
      }

      &.playing {
        img {
          display: none;
        }

        .icon-video {
          display: none;
        }

        &:after {
          z-index: 0;
          background-color: transparent;
        }
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 11;
        background-color: rgba(0, 0, 0, 0.3);
      }
    }

    &.banner {
      height: 191px;
    }
  }

  .product-body {
    padding: 0 15px 15px;
    background-color: #fff;

    .price-box {
      padding-top: 20px;
      display: flex;
      align-items: flex-end;

      .vip {
        color: #ff98a0;
        font-size: 18px;
      }

      .number {
        font-size: 30px;
        font-weight: bold;
      }

      .vip-text {
        color: #fff;
        font-size: 12px;
        padding: 4px 7px;
        line-height: 10px;
        border-radius: 18px;
        display: inline-block;
        background-color: #ffb5bb;
      }

      del {
        color: #b5b5b5;
        padding-left: 5px;
      }

      .operate {
        margin-left: auto;

        .share-icon {
          color: #fff;
          font-size: 24px;
          padding: 2px 4px;
          border-radius: 50%;
          background-color: #979797;
        }

        .text {
          color: #333;
          font-size: 10px;
          padding-top: 5px;
          text-align: center;
        }
      }
    }

    .pro-name {
      color: #666;
      padding: 10px 0;
      font-size: 16px;
      font-weight: bold;
    }

    .delivery-time {
      color: #ff98a0;
    }

    .sold-num {
      color: #b5b5b5;
    }
  }

  .mi-norm {
    margin-top: 10px;
    padding: 11px 15px;
    background-color: #fff;

    .mi-item {
      padding: 15px 0;
      overflow: hidden;
      position: relative;

      label {
        float: left;
        color: #999;
        width: 50px;
      }

      .mi-item-body {
        overflow: hidden;

        p {
          color: #666;
          font-size: 12px;
        }
      }

      &:after {
        content: '';
        clear: both;
        position: absolute;
        left: 50px;
        right: 0;
        bottom: 0;
        height: 1px;
        background-color: #f1f1f1;
      }

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;

        &:after {
          height: 0;
        }
      }
    }
  }

  .recommend {
    padding: 11px 0;
    margin-top: 10px;
    background-color: #fff;

    .sub-title {
      color: #666;
      font-size: 14px;
      padding-left: 15px;
      margin-bottom: 15px;
    }

    .van-swipe-item {
      overflow: hidden;
      padding: 0 15px;
      position: relative;
      box-sizing: border-box;

      .mi-media-object {
        width: 80px;
        height: 80px;
        float: left;
        margin-right: 10px;
      }

      .mi-media-body {
        .pro-name {
          color: #636363;
          font-size: 14px;
        }

        .price-box {
          padding-top: 10px;

          .vip {
            color: #ff98a0;
            font-size: 16px;
            font-weight: bold;
          }

          .vip-text {
            color: #fff;
            font-size: 10px;
            padding: 2px 4px;
            line-height: 10px;
            border-radius: 14px;
            display: inline-block;
            background-color: #ffb5bb;
          }

          del {
            color: #b5b5b5;
          }
        }

        .sold-num {
          color: #b5b5b5;
        }
      }

      &:after {
        content: '';
        clear: both;
        position: absolute;
        top: 15px;
        right: 15px;
        bottom: 15px;
        width: 1px;
        background-color: #f1f1f1;
      }

      &:last-child {
        &:after {
          width: 0;
        }
      }
    }
  }

  .pro-tabs {
    border-top: 10px solid #f8f8f8;

    .van-tabs__line {
      display: none;
    }

    .van-tab {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        top: 13px;
        right: 0;
        bottom: 13px;
        width: 1px;
        background-color: #f1f1f1;
      }

      &:last-child {
        &:after {
          width: 0;
        }
      }

      &.van-tab--active {
        color: #ff98a0;
      }
    }

    .van-tabs__content {
      .sub-title {
        padding: 15px 0;
        position: relative;
        text-align: center;
        background-color: #fff;

        span {
          color: #999;
          z-index: 10;
          padding: 0 15px;
          position: relative;
          background-color: #fff;
        }

        &:after {
          content: '';
          position: absolute;
          left: 15%;
          right: 15%;
          bottom: 23px;
          height: 1px;
          z-index: 1;
          background-color: #f1f1f1;
        }
      }

      .pro-detail {
        ::v-deep img {
          max-width: 100%;
        }
      }

      .certificate {}
    }
  }

  .van-image-preview {
    .van-swipe-item {
      img {
        height: auto;
        max-height: initial;
      }
    }
  }


  .footer {
    .mui-bar-tab {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      height: 70px;
      z-index: 999;
      display: flex;
      padding: 0 15px;
      background-color: #fff;
      -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0.85);
      box-shadow: 0 0 1px rgba(0, 0, 0, 0.85);

      .car-but {
        position: absolute;
        top: -9px;
        left: 15px;
        z-index: 996;
        color: #fff;
        width: 48px;
        height: 48px;
        text-align: center;
        border-radius: 50%;
        display: inline-block;
        background-color: #ffb5bb;

        .box {
          border-radius: 50%;
          border: 2px solid #fff;
        }

        [class^=icon-] {
          font-size: 22px;
          line-height: 44px;
        }

        .mui-badge {
          position: absolute;
          top: -5px;
          right: -8px;
          color: #ff98a0;
          font-size: 12px;
          line-height: 1;
          padding: 3px 6px;
          border-radius: 100px;
          display: inline-block;
          border: 1px solid #ff98a0;
          background-color: #fff;
        }
      }

      .car-text {
        position: absolute;
        bottom: 5px;
        left: 70px;
        color: #999;
        font-size: 12px;

        .car-money {
          color: #ff98a0;
          font-size: 16px;

          s {
            color: #666;
            font-size: 14px;
            font-weight: bold;
          }
        }

        .font-pink {
          color: #FF98A0;
        }
      }

      .buy-box {
        position: absolute;
        right: 0;
        bottom: 7px;
        width: 114px;
        border: 0;
        padding: 0;
        z-index: 8;
        background-color: inherit;
        -webkit-transition: all 1s ease-out;
        -moz-transition: all 1s ease-out;

        input {
          margin: 0;
          width: 100%;
          color: #333;
          border: none;
          padding: 0 22px;
          height: 28px;
          box-sizing: border-box;
          display: inline-block;
          background-color: #f5f5f5;
          -webkit-text-fill-color: #333;
          -webkit-opacity: 1;
        }

        button {
          position: absolute;
          z-index: 6;
          padding: 0;
          height: 100%;
          border: none;
          font-size: 22px;
          background-color: #fff;

          &:before {
            top: 0;
            width: auto;
            height: auto;
            background-color: transparent;
            font-family: rolormd !important;
          }
        }

        .van-stepper__plus {
          right: 0;
          color: #ff98a0;

          &:before {
            content: "\e96c";
          }

          &:after {
            width: auto;
            height: auto;
            background-color: transparent;
          }

          &.van-stepper__plus--disabled {
            opacity: 0.6;
          }
        }

        .van-stepper__minus {
          color: #aaa;

          &:before {
            content: "\ea10";
          }

          &.van-stepper__minus--disabled {
            opacity: 0.6;
          }
        }

        &.hide-minus-btn {
          .van-stepper__minus {
            opacity: 0;
          }

          .van-stepper__input {
            opacity: 0;
          }
        }
      }
    }

    .prompt {
      position: fixed;
      left: 15px;
      right: 15px;
      bottom: 5px;
      color: #fff;
      z-index: 1000;
      font-size: 16px;
      line-height: 33px;
      text-align: center;
      border-radius: 33px;
      background-color: #ff98a0;
    }
  }

  .mui-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
  }

  .mi-share {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10000;
    background-color: rgba(0, 0, 0, 0.6);

    .mi-poster {
      position: absolute;
      top: 20%;
      left: 10%;
      right: 10%;
      z-index: 10;
      font-size: 0;
      box-sizing: border-box;

      img {
        max-width: 100%;
      }

      .prompt {
        color: #666;
        font-size: 22px;
        margin-top: -20px;
        position: relative;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 30px 0 10px;
        background-color: #fff;
        border-radius: 0 0 30px 30px;

        &:before {
          content: '';
          position: absolute;
          top: 20px;
          left: 0;
          right: 0;
          height: 1px;
          background-color: #f5f5f5;
        }

        img {
          max-width: 15px;
        }

        span {
          font-size: 13px;

          &:last-child {
            color: #ff98a0;
          }
        }
      }
    }

    canvas {
      opacity: 0;
    }
  }

  .ivv-notice {
    >img {
      width: 100%;
    }
  }
}

.footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 50px;
  display: flex;
  z-index: 9999;
  padding: 0 16px;
  align-items: center;
  border-top: 1px solid #f1f1f1;
  justify-content: space-between;
  background-color: #fff;

  .shop-cart {
    position: absolute;
    top: 7px;
    left: 16px;
    word-break: break-all;
    width: 30px;
    padding: 2px 6px;
    color: #fff;
    font-size: 12px;
    border-radius: 20%;
    text-align: center;
    background-color: #c4b3ff;
  }

  .total {
    text-align: left;
    padding-left: 49px;

    span {
      color: #c4b3ff;
    }

    .amount {
      font-size: 16px;
      font-weight: bold;
    }

    p {
      color: #999999;
      font-size: 10px;
    }
  }

  .van-button {
    height: 33px;
    padding: 0 23px;
  }
}

.iphoneX {
  .container {
    padding-bottom: 84px;
  }

  .footer {
    padding-bottom: 34px;
  }
}
</style>